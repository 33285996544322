<template>
  <div class="testbody">
    <!-- 顶部 -->
    <div class="disp_flex" style="margin: 8px 0">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="检查日期：">
          <el-date-picker v-model="formInline.date" value-format="YYYY-MM-DD" type="date" placeholder="开始日期"
            ></el-date-picker>
        </el-form-item>
        <el-form-item label="化验大项：">
          <el-input v-model="formInline.name" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>

      <div>
        <el-button class="color-main a1" type="primary" @click="tobody">
          查询
        </el-button>
      </div>
    </div>
    <!-- 中间选择 -->
    <!-- <div class="bodyrodoi">
      <div class="dialysisType flex-between">
        <div
          class="rodoi-left"
          :class="dialysisTypeLeft == 0 ? 'default' : ''"
          @click="dialysisTypeBut('left')"
        >
          <i class="fa fa-angle-left"></i>
        </div>
        <div class="bodyrodoiBox" ref="dialysisTypeRef">
          <div
            ref="dialysisItemRef"
            class="flex-start bodyrodoiBoxItem"
            :style="{
              left: dialysisTypeLeft + 'px',
            }"
          >
            <div v-for="item in dictList[100000]" :key="item.code">
              <div
                class="radios"
                :class="[actDialyse.code === item.code ? 'active' : '']"
                @click="rodeiclis(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="rodoi-right"
          :class="dialysisTypeLeft == -dialysisTypeWin ? 'default' : ''"
          @click="dialysisTypeBut('right')"
        >
          <i class="fa fa-angle-right"></i>
        </div>
      </div>
    </div> -->
    <!-- 表格 -->
    <div class="disp_flex">
      <!-- 左侧表格 -->
      <div style="width: 450px">
        <el-table ref="singleTableRef" :data="tableData" style="width: 100%" highlight-current-row border
          v-loading="loading" @current-change="Rowclick">
          <el-table-column prop="date_rep" width="200" label="检查日期" />
          <el-table-column prop="nm_em_item" width="180" label="化验大项" />
          <el-table-column prop="nm_rep_psn" width="100" label="化验员" />
          <el-table-column prop="address" fixed="right" label="操作" width="80">
            <template #default="scope">
              <el-button type="text" size="small" @click="addRemind(scope.row)">
                提醒
              </el-button>
              <!-- <el-popconfirm
                title="是否确定删除?"
                @confirm="delParient(scope.row)"
              >
                <template #reference>
                  <el-button type="text" size="small">
                    <span class="txfonts del">删</span>
                  </el-button>
                </template>
              </el-popconfirm> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="disp_flex" style="margin: 8px 0">
          <div></div>
          <el-pagination small background layout="prev, pager, next" :total="total" class="mt-4"
            @current-change="currentchange" />
        </div>
      </div>
      <!-- 右侧表格 -->
      <div style="width: calc(100% - 466px)">
        <el-table :data="RtableData" style="width: 100%" border height="400">
          <el-table-column prop="nm_index_lis" label="检测名称" />
          <el-table-column prop="value_lis" label="结果"> </el-table-column>
          <el-table-column prop="nm_quanti_unit" label="单位" />
          <el-table-column label="参考范围">
            <template #default="scope">
              <span>{{ scope.row.limit_high }}</span>
              -
              <span>{{ scope.row.limit_low }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 导入弹窗 -->
    <div>
      <BaseDialog :title="'导入化验数据'" :isshow="adshow" @handleShow="
          (val) => {
            adshow = val
          }
        " width="27%" height="auto">
        <div class="DialogDR">
          <el-form :model="form" label-width="100px">
            <el-form-item label="时间范围：">
              <el-date-picker v-model="form.time" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="导入模式：">
              <el-radio v-model="form.radio1" label="1">当前患者(用户名称)</el-radio>
              <el-radio v-model="form.radio1" label="2">全部患者</el-radio>
            </el-form-item>
          </el-form>
        </div>
        <!-- 下划线 -->
        <el-divider />
        <!-- 底部按钮 -->
        <div class="disp_flex">
          <div></div>
          <div class="disp_flex">
            <el-button @click="adshow = false">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
          </div>
        </div>
      </BaseDialog>
    </div>

    <!-- 新增弹窗 -->
    <div>
      <BaseDialog :title="'新增血常规记录'" :isshow="show" @handleShow="
          (val) => {
            show = val
          }
        " width="65%" height="auto">
        <div class="Dialogadd">
          <el-form :model="ruleForm" label-width="160px" :inline="true" :rules="rules">
            <el-form-item label="姓名：" prop="username">
              <el-input v-model="ruleForm.username" />
            </el-form-item>
            <el-form-item label="病案号：">
              <el-input v-model="ruleForm.username" />
            </el-form-item>
            <el-form-item label="结果报告类型：">
              <el-select v-model="value" class="m-2" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="婴儿标志：">
              <el-select v-model="value" class="m-2" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="报告医生：" prop="username">
              <el-input v-model="ruleForm.username" />
            </el-form-item>
            <el-form-item label="检查日期：">
              <el-date-picker v-model="ruleForm.value1" type="date" />
            </el-form-item>
            <el-form-item label="报告日期：">
              <el-date-picker v-model="ruleForm.value2" type="date" />
            </el-form-item>
            <!-- 下划线 -->
            <el-divider />

            <el-form-item label="血红蛋白：">
              <el-input v-model="ruleForm.input2">
                <template #append>g/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="平均血红蛋白：">
              <el-input v-model="ruleForm.input2">
                <template #append>pg</template>
              </el-input>
            </el-form-item>
            <el-form-item label="平均血红蛋白浓度：">
              <el-input v-model="ruleForm.input2">
                <template #append>g/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="红细胞计数：">
              <el-input v-model="ruleForm.input2">
                <template #append>10^12/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="红细胞压积-：">
              <el-input v-model="ruleForm.input2">
                <template #append>%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="红细胞平均体积：">
              <el-input v-model="ruleForm.input2">
                <template #append>fL</template>
              </el-input>
            </el-form-item>
            <el-form-item label="红细胞分布宽度：">
              <el-input v-model="ruleForm.input2">
                <template #append>%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="红细胞沉降率：">
              <el-input v-model="ruleForm.input2">
                <template #append>mm/h</template>
              </el-input>
            </el-form-item>
            <el-form-item label="白细胞计数：">
              <el-input v-model="ruleForm.input2">
                <template #append>10^9/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="中性粒细胞：">
              <el-input v-model="ruleForm.input2">
                <template #append>10^9/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="中性粒细胞百分比：">
              <el-input v-model="ruleForm.input2">
                <template #append>%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="嗜酸粒细胞：">
              <el-input v-model="ruleForm.input2">
                <template #append>10^9/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="嗜酸性粒细胞百分比：">
              <el-input v-model="ruleForm.input2">
                <template #append>%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="嗜碱粒细胞：">
              <el-input v-model="ruleForm.input2">
                <template #append>10^9/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="嗜碱粒细胞百分比：">
              <el-input v-model="ruleForm.input2">
                <template #append>%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="淋巴细胞：">
              <el-input v-model="ruleForm.input2">
                <template #append>10^9/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="淋巴细胞百分比：">
              <el-input v-model="ruleForm.input2">
                <template #append>%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="单核细胞：">
              <el-input v-model="ruleForm.input2">
                <template #append>10^9/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="单细胞百分比：">
              <el-input v-model="ruleForm.input2">
                <template #append>%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="血小板计数：">
              <el-input v-model="ruleForm.input2">
                <template #append>10^9/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="血小板分布宽度：">
              <el-input v-model="ruleForm.input2">
                <template #append>fl</template>
              </el-input>
            </el-form-item>
            <el-form-item label="平均血小板体积：">
              <el-input v-model="ruleForm.input2">
                <template #append>fL</template>
              </el-input>
            </el-form-item>
            <el-form-item label="血小板压积：">
              <el-input v-model="ruleForm.input2">
                <template #append>%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="大血小板比率：">
              <el-input v-model="ruleForm.input2">
                <template #append>%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="网红细胞百分比：">
              <el-input v-model="ruleForm.input2">
                <template #append>%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="网织红细胞：">
              <el-input v-model="ruleForm.input2">
                <template #append>10^9/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="Na：">
              <el-input v-model="ruleForm.input2">
                <template #append>mmol/L</template>
              </el-input>
            </el-form-item>
            <el-form-item label="HCO3：">
              <el-input v-model="ruleForm.input2">
                <template #append>mmol/L</template>
              </el-input>
            </el-form-item>
            <!-- 下划线 -->
            <el-divider />
            <el-form-item label="报告备注：" style="width: 95%">
              <el-input v-model="textarea" maxlength="1000" placeholder="请输入" show-word-limit type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <!-- 下划线 -->
        <el-divider />
        <!-- 底部按钮 -->
        <div class="disp_flex">
          <div></div>
          <div class="disp_flex">
            <el-button @click="show = false">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
          </div>
        </div>
      </BaseDialog>
    </div>
    <!-- 提醒日历 -->
    <BaseDialog title="日历提醒" :isshow="modRemind" @handleShow="
        (val) => {
          modRemind = val
        }
      " width="650px" height="auto">
      <div class="schemeBox">
        <div class="userItem">
          <el-form ref="ruleFormRef" :model="ruleForm1" :rules="rules1" label-width="84px" label-position="right"
            class="demo-ruleForm col-333">
            <el-form-item label="提醒日期" prop="notice_date">
              <el-date-picker v-model="ruleForm1.notice_date" type="date" value-format="YYYY-MM-DD"
                placeholder="请选择提醒日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="提醒内容" prop="notice_content">
              <el-input type="textarea" :rows="4" placeholder="请输入提醒内容" v-model="ruleForm1.notice_content">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button class="color-cancel" type="primary" @click="modRemind = false">
            取消
          </el-button>
          <el-button @click="saveRemind" :loading="saveLoading" class="color-determine" type="primary">
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
  import { reactive, toRefs, onMounted, ref, nextTick } from 'vue'
  import { gitDictList } from '@/utils/tool'
  import { useRoute } from 'vue-router'
  import BaseDialog from '@/components/Dialog/index.vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import service from '@/utils/request'
  export default {
    components: {
      BaseDialog,
    },
    setup() {
      const route = useRoute()
      const state = reactive({
        formInline: {
          radio: 0,
        },
        dictList: {},
        actDialyse: {},
        total: 0,
        loading: true,
        show: false,
        adshow: false,
        dialysisTypeWin: 0,
        dialysisTypeLeft: 0,
        form: {
          radio1: '1',
        },
        ruleForm: {},
        ruleForm1: {},
        current: 1,
        type_code: '',
        tableData: [],
        RtableData: [],
        singleTableRef: null,
        modRemind: false,
        rules1: {
          notice_date: [
            { required: true, message: '请选择提醒日期', trigger: 'change' },
          ],
          notice_content: [
            { required: true, message: '请输入提醒内容', trigger: 'blur' },
          ],
        },
      })
      const initData = async () => {
        gitDict()
        tobody()
      }
      // 获得字典
      const gitDict = async () => {
        state.dictList = await gitDictList(['100000'])
        let data = {
          name: '全部',
        }
        if (state.dictList[100000]) {
          state.dictList[100000].unshift(data)
          if (state.dictList[100000].length > 0) {
            state.actDialyse = state.dictList[100000][0]
          }
        }
      }
      const ruleFormRef = ref(null)
      // 获取列表
      const tobody = async () => {
        state.loading = true
        let data = {
          size: 10,
          current: state.current,
          patient_id: route.params.id,
          date:state.formInline.date,
          name:state.formInline.name
        }
        let res = await service.post(
          '/api/patient/get_his_patient_rep_list',
          data
        )
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableData = res.data.list
            state.total = res.data.total
            detail(res.data.list[0].id)
            if (state.tableData.length > 0) {
              state.singleTableRef.setCurrentRow(state.tableData[0])
            }
          } else {
            state.tableData = []
            state.total = 0
          }
        }
        state.loading = false
      }
      // 选择切换
      const rodeiclis = (item) => {
        state.actDialyse = item
        state.type_code = item.code
        tobody()
      }
      // 左右箭头点击
      const dialysisTypeBut = (type) => {
        if (!state.dialysisTypeWin) return
        if (type === 'left') {
          if (state.dialysisTypeLeft === 0) return
          if (state.dialysisTypeLeft < -200) {
            state.dialysisTypeLeft += 200
          } else {
            state.dialysisTypeLeft = 0
          }
        } else {
          const par = state.dialysisTypeLeft * -1

          if (par === state.dialysisTypeWin) return
          if (par + 200 < state.dialysisTypeWin) {
            state.dialysisTypeLeft += -200
          } else {
            state.dialysisTypeLeft = state.dialysisTypeWin * -1
          }
          // console.log(state.dialysisTypeLeft)
        }
      }
      // 新增
      const add = () => {
        state.show = true
      }
      // 表单验证
      const rules = {
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
        ],
      }
      // 左侧表格点击
      const Rowclick = (row) => {
        detail(row.report_id)
      }
      // 右侧表格列表
      const detail = async (id) => {
        if (!id) return
        let res = await service.post('/api/patient/get_report_detail', {
          report_id: id,
        })
        if (res.data.list.length > 0) {
          state.RtableData = res.data.list
        } else {
          state.RtableData = []
        }
      }
      // 点击分页
      const currentchange = (index) => {
        state.current = index
        tobody()
      }
      const addRemind = (row) => {
        state.modRemind = true
        // state.ruleForm1 = {}
        state.ruleForm1.notice_content = row.nm_em_item
        nextTick(() => {
          ruleFormRef.value.clearValidate()
        })
      }
      const openRemind = (row) => {
        //   state.modRemind = true
        //   state.ruleForm1 = JSON.parse(JSON.stringify(row))
        //   state.ruleForm1.notice_content = row.nm_em_item
        //   nextTick(() => {
        //     ruleFormRef.value.clearValidate()
        //   })
      }
      const saveRemind = () => {
        ruleFormRef.value.validate(async (valid) => {
          if (valid) {
            let url = '/api/patient_notice/create_notice'
            state.ruleForm1.patient_id = route.params.id
            let res = await service.post(url, state.ruleForm1)
            if (res.code === 0) {
              ElMessage.success(res.msg)
              tobody()
              state.modRemind = false
            }
          }
        })
      }
      const tagName = ref('test')
      return {
        ...toRefs(state),
        gitDict,
        tagName,
        initData,
        rodeiclis,
        dialysisTypeBut,
        add,
        rules,
        tobody,
        Rowclick,
        saveRemind,
        detail,
        currentchange,
        addRemind,
        openRemind,
        ruleFormRef
      }
    },
  }
</script>

<style scoped lang="scss">
  .testbody {
    height: 706px;
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;
    opacity: 1;

    &:deep(.el-divider--horizontal) {
      margin: 8px 0;
    }
  }

  .disp_flex {
    display: flex;
    justify-content: space-between;
  }

  .a1 {
    background: rgba(49, 102, 174, 0.1);
    opacity: 1;
    border: none;
    color: #3166ae;
  }

  .bodyrodoi {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .rodoi-left,
    .rodoi-right {
      width: 32px;
      height: 32px;
      background-color: #fff;
      cursor: pointer;
      z-index: 1;
      display: flex;
      justify-items: center;
      align-items: center;

      &.default {
        cursor: not-allowed;
      }

      .fa {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.24);
        text-align: center;
        color: #3166ae;
        font-size: 24px;
      }
    }

    .dialysisType {
      flex: 1;
      overflow: hidden;

      .bodyrodoiBox {
        width: calc(100% - 64px);
        position: relative;

        .bodyrodoiBoxItem {
          transition: left 0.3s;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  .radios {
    height: 32px;
    line-height: 32px;
    background: #fff;
    border-radius: 4px;
    text-align: center;
    padding: 0 10px;
    color: #3166ae;
    margin-right: 8px;
    border: 1px solid rgba(49, 102, 174, 1);
    cursor: pointer;
    white-space: nowrap;

    &.active {
      background: #3166ae;
      color: #fff;
    }
  }

  .modify {
    background-color: #3166ae;
  }

  .del {
    background-color: #ffecec;
    color: #ff6b6b;
    font-weight: 700;
  }

  .icons {
    width: 18px;
    height: 18px;
    background: #3166ae;
    opacity: 1;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    cursor: pointer;
  }

  .DialogDR {
    margin: 8px 0;
  }

  .Dialogadd {
    margin: 8px 0;
    height: 700px;
    overflow-y: scroll;

    .el-form {
      flex-wrap: wrap;

      &:deep(.el-form-item__content) {
        width: 200px !important;
      }
    }
  }

  .arrowup {
    color: #ff3d49;
  }

  .arrowdown {
    color: #2984ef;
  }

  .search-top {
    margin-bottom: 12px;
  }

  .schemeBox {
    margin-top: 16px;
  }
</style>